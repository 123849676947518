"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobStatusEnum = void 0;
var JobStatusEnum;
(function (JobStatusEnum) {
    JobStatusEnum["PENDING"] = "pending";
    JobStatusEnum["QUEUED"] = "queued";
    JobStatusEnum["RUNNING"] = "running";
    JobStatusEnum["COMPLETED"] = "completed";
    JobStatusEnum["FAILED"] = "failed";
    JobStatusEnum["DELAYED"] = "delayed";
    JobStatusEnum["CANCELED"] = "canceled";
})(JobStatusEnum = exports.JobStatusEnum || (exports.JobStatusEnum = {}));

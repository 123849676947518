"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chatProviders = void 0;
const message_template_1 = require("../../../entities/message-template");
const credentials_1 = require("../credentials");
const provider_enum_1 = require("../provider.enum");
exports.chatProviders = [
    {
        id: provider_enum_1.ChatProviderIdEnum.Slack,
        displayName: 'Slack',
        channel: message_template_1.ChannelTypeEnum.CHAT,
        credentials: credentials_1.slackConfig,
        docReference: 'https://api.slack.com/docs',
        logoFileName: { light: 'slack.svg', dark: 'slack.svg' },
    },
    {
        id: provider_enum_1.ChatProviderIdEnum.Discord,
        displayName: 'Discord',
        channel: message_template_1.ChannelTypeEnum.CHAT,
        credentials: [],
        docReference: 'https://discord.com/developers/docs/intro',
        logoFileName: { light: 'discord.svg', dark: 'discord.svg' },
    },
    {
        id: provider_enum_1.ChatProviderIdEnum.MsTeams,
        displayName: 'MSTeams',
        channel: message_template_1.ChannelTypeEnum.CHAT,
        credentials: [],
        docReference: 'https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook',
        logoFileName: { light: 'msteams.svg', dark: 'msteams.svg' },
    },
];

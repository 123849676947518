"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContextPath = exports.NovuComponentEnum = void 0;
var NovuComponentEnum;
(function (NovuComponentEnum) {
    NovuComponentEnum[NovuComponentEnum["WEB"] = 0] = "WEB";
    NovuComponentEnum[NovuComponentEnum["API"] = 1] = "API";
    NovuComponentEnum[NovuComponentEnum["WIDGET"] = 2] = "WIDGET";
    NovuComponentEnum[NovuComponentEnum["WS"] = 3] = "WS";
})(NovuComponentEnum = exports.NovuComponentEnum || (exports.NovuComponentEnum = {}));
function getContextPath(component) {
    let contextPath = '';
    if (process.env.GLOBAL_CONTEXT_PATH) {
        contextPath += process.env.GLOBAL_CONTEXT_PATH + '/';
    }
    switch (component) {
        case NovuComponentEnum.API:
            if (process.env.API_CONTEXT_PATH) {
                contextPath += process.env.API_CONTEXT_PATH + '/';
            }
            break;
        case NovuComponentEnum.WEB:
            if (process.env.FRONT_BASE_CONTEXT_PATH) {
                contextPath += process.env.FRONT_BASE_CONTEXT_PATH + '/';
            }
            break;
        case NovuComponentEnum.WIDGET:
            if (process.env.WIDGET_CONTEXT_PATH) {
                contextPath += process.env.WIDGET_CONTEXT_PATH + '/';
            }
            break;
        case NovuComponentEnum.WS:
            if (process.env.WS_CONTEXT_PATH) {
                contextPath += process.env.WS_CONTEXT_PATH + '/';
            }
            break;
    }
    return contextPath;
}
exports.getContextPath = getContextPath;

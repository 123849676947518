"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pushProviders = void 0;
const message_template_1 = require("../../../entities/message-template");
const credentials_1 = require("../credentials");
const provider_enum_1 = require("../provider.enum");
exports.pushProviders = [
    {
        id: provider_enum_1.PushProviderIdEnum.FCM,
        displayName: 'Firebase Cloud Messaging',
        channel: message_template_1.ChannelTypeEnum.PUSH,
        credentials: credentials_1.fcmConfig,
        docReference: 'https://docs.novu.co/channels/push/fcm',
        logoFileName: { light: 'fcm.svg', dark: 'fcm.svg' },
    },
    {
        id: provider_enum_1.PushProviderIdEnum.EXPO,
        displayName: 'Expo Push',
        channel: message_template_1.ChannelTypeEnum.PUSH,
        credentials: credentials_1.expoConfig,
        docReference: 'https://docs.expo.dev/push-notifications/overview/',
        logoFileName: { light: 'expo.svg', dark: 'expo.svg' },
    },
    {
        id: provider_enum_1.PushProviderIdEnum.APNS,
        displayName: 'APNs',
        channel: message_template_1.ChannelTypeEnum.PUSH,
        credentials: credentials_1.apnsConfig,
        docReference: 'https://docs.novu.co/channels/push/apns',
        logoFileName: { light: 'apns.png', dark: 'apns.png' },
        betaVersion: true,
    },
];

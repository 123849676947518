"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.firetextConfig = exports.gupshupConfig = exports.apnsConfig = exports.expoConfig = exports.fcmConfig = exports.slackConfig = exports.twilioConfig = exports.telnyxConfig = exports.snsConfig = exports.termiiConfig = exports.sms77Config = exports.plivoConfig = exports.sesConfig = exports.sendinblueConfig = exports.netCoreConfig = exports.sendgridConfig = exports.postmarkConfig = exports.nodemailerConfig = exports.mandrillConfig = exports.nexmoConfig = exports.mailjetConfig = exports.mailgunConfig = exports.mailJsConfig = void 0;
const provider_enum_1 = require("../provider.enum");
const mailConfigBase = [
    {
        key: provider_enum_1.CredentialsKeyEnum.From,
        displayName: 'From email address',
        description: 'Use the same email address you used to authenticate your delivery provider',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.SenderName,
        displayName: 'Sender name',
        type: 'string',
        required: true,
    },
];
const smsConfigBase = [
    {
        key: provider_enum_1.CredentialsKeyEnum.From,
        displayName: 'From',
        type: 'string',
        required: true,
    },
];
const pushConfigBase = [];
exports.mailJsConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.SecretKey,
        displayName: 'Secret key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
exports.mailgunConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.BaseUrl,
        displayName: 'Base URL',
        type: 'string',
        required: false,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.User,
        displayName: 'User name',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.Domain,
        displayName: 'Domain',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
exports.mailjetConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.SecretKey,
        displayName: 'API Secret',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
exports.nexmoConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.SecretKey,
        displayName: 'API secret',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
exports.mandrillConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
exports.nodemailerConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.User,
        displayName: 'User',
        type: 'string',
        required: false,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.Password,
        displayName: 'Password',
        type: 'string',
        required: false,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.Host,
        displayName: 'Host',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.Port,
        displayName: 'Port',
        type: 'number',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.Secure,
        displayName: 'Secure',
        type: 'boolean',
        required: false,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.Domain,
        displayName: 'DKIM: Domain name',
        type: 'string',
        required: false,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.SecretKey,
        displayName: 'DKIM: Private key',
        type: 'string',
        required: false,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.AccountSid,
        displayName: 'DKIM: Key selector',
        type: 'string',
        required: false,
    },
    ...mailConfigBase,
];
exports.postmarkConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
exports.sendgridConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
exports.netCoreConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
exports.sendinblueConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
exports.sesConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'Access key ID',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.SecretKey,
        displayName: 'Secret access key',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.Region,
        displayName: 'Region',
        type: 'string',
        required: true,
    },
    ...mailConfigBase,
];
exports.plivoConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.AccountSid,
        displayName: 'Account SID',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.Token,
        displayName: 'Auth token',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
exports.sms77Config = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
exports.termiiConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
exports.snsConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'Access key ID',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.SecretKey,
        displayName: 'Secret access key',
        type: 'string',
        required: true,
    },
];
exports.telnyxConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.MessageProfileId,
        displayName: 'Message profile ID',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
exports.twilioConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.AccountSid,
        displayName: 'Account SID',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.Token,
        displayName: 'Auth token',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];
exports.slackConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ClientId,
        displayName: 'Client ID',
        type: 'string',
        required: false,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.SecretKey,
        displayName: 'Client Secret',
        type: 'string',
        required: false,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.ApplicationId,
        displayName: 'Application Id',
        type: 'string',
        required: false,
    },
];
exports.fcmConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ServiceAccount,
        displayName: 'Service Account (entire JSON file)',
        type: 'text',
        required: true,
    },
    ...pushConfigBase,
];
exports.expoConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'Access Token',
        type: 'text',
        required: true,
    },
    ...pushConfigBase,
];
exports.apnsConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.SecretKey,
        displayName: 'Private Key',
        type: 'text',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'Key ID',
        type: 'text',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.ProjectName,
        displayName: 'Team ID',
        type: 'text',
        required: true,
    },
    ...pushConfigBase,
];
exports.gupshupConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.User,
        displayName: 'User id',
        type: 'string',
        required: true,
    },
    {
        key: provider_enum_1.CredentialsKeyEnum.Password,
        displayName: 'Password',
        type: 'string',
        required: true,
    },
];
exports.firetextConfig = [
    {
        key: provider_enum_1.CredentialsKeyEnum.ApiKey,
        displayName: 'API Key',
        type: 'string',
        required: true,
    },
    ...smsConfigBase,
];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DelayTypeEnum = exports.DigestTypeEnum = exports.DigestUnitEnum = void 0;
var DigestUnitEnum;
(function (DigestUnitEnum) {
    DigestUnitEnum["SECONDS"] = "seconds";
    DigestUnitEnum["MINUTES"] = "minutes";
    DigestUnitEnum["HOURS"] = "hours";
    DigestUnitEnum["DAYS"] = "days";
})(DigestUnitEnum = exports.DigestUnitEnum || (exports.DigestUnitEnum = {}));
var DigestTypeEnum;
(function (DigestTypeEnum) {
    DigestTypeEnum["REGULAR"] = "regular";
    DigestTypeEnum["BACKOFF"] = "backoff";
})(DigestTypeEnum = exports.DigestTypeEnum || (exports.DigestTypeEnum = {}));
var DelayTypeEnum;
(function (DelayTypeEnum) {
    DelayTypeEnum["REGULAR"] = "regular";
    DelayTypeEnum["SCHEDULED"] = "scheduled";
})(DelayTypeEnum = exports.DelayTypeEnum || (exports.DelayTypeEnum = {}));
